import { Injectable } from "@angular/core";
import {
  CanActivate,
  ActivatedRouteSnapshot,
  RouterStateSnapshot,
  Router
} from "@angular/router";
import { Observable } from "rxjs";
import { AuthenticationService } from "app/service/authentication.service";
import { JwtHelperService } from "@auth0/angular-jwt";

@Injectable()
export class AdminguardGuard implements CanActivate {
  constructor(
    private authService: AuthenticationService,
    private router: Router,
    private jwtHelperService: JwtHelperService
  ) {}
  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean> | Promise<boolean> | boolean {
    const expectedRole = next.data.expectedRole;
    const token = localStorage.getItem("authtoken");
    const tokenPayLoad = this.jwtHelperService.decodeToken(token);

    if (this.authService.loggedIn()) {
      /* console.log('Token Payload details details are' + JSON.stringify(tokenPayLoad));
    console.log("expectedRole is" + expectedRole);
    console.log("tokenPayLoad.role" + tokenPayLoad.data.role); */
      if (tokenPayLoad.data && tokenPayLoad.data.role !== "undefined" && tokenPayLoad.data.role.trim() !== expectedRole.trim()) {
        if (expectedRole === "admin") {
          /* console.log("Admin not allowed"); */
          this.router.navigate(["admin"]);
          return false;
        } else {
          /* console.log("User not allowed"); */
          this.router.navigate(["signin"]);
          return false;
        }
      }
      return true;
    } else {
      /* console.log('The Role of user is ' + expectedRole); */
      if (expectedRole === "admin") {
        /* console.log("Admin not allowed"); */
        this.router.navigate(["admin"]);
        return false;
      } else {
        /* console.log("User not allowed"); */
        this.router.navigate(["signin"]);
        return false;
      }
    }
  }
}
