import { Injectable } from "@angular/core";
import { Observable, throwError } from "rxjs";
import { catchError, map } from "rxjs/operators";
import {
  HttpClient,
  HttpHeaders,
  HttpErrorResponse
} from "@angular/common/http";

/* JWT AUTHENTICATION */
import { JwtHelperService } from "@auth0/angular-jwt";
import { environment } from "../../environments/environment";
const API_URL = environment.apiUrl + "/admin";
@Injectable()
export class AuthenticationService {
  authToken: any;
  user: any;
  _url: string;
  constructor(
    private http: HttpClient,
    private jwtHelperService: JwtHelperService
  ) {
    // this.isDev = true;  // Change to false before deployment
  }
  registerUser(user) {
    let headers = new HttpHeaders();
    return this.http
      .post(API_URL + "/register", user, { headers: headers })
      .pipe(map(res => res));
  }
  authenticateUser(user) {
    let headers = new HttpHeaders();
    headers.append("Content-Type", "application/json");
    return this.http
      .post(API_URL + "/authenticate", user, { headers: headers })
      .pipe(map(res => res));
  }

  /* checking jwt token expiration */
  loggedIn() {
    const token: string = this.jwtHelperService.tokenGetter();
    if (!token) {
      return false;
    } else {
      return true;
    }
    /* const tokenExpired: boolean = this.jwtHelperService.isTokenExpired(token);
    return !tokenExpired; */
  }

  /* clear the userdata & logout user */
  logout() {
    this.authToken = null;
    this.user = null;
    localStorage.clear();
  }

  /* store userdata after successful authentication */
  storeUserData(token, user) {
    localStorage.setItem("authtoken", token);
    localStorage.setItem("user", JSON.stringify(user));
    this.authToken = token;
    this.user = user;
  }

  /* get the stored token */
  loadToken() {
    const token = localStorage.getItem("authtoken");
    this.authToken = token;
  }

  getProfile() {
    this.loadToken();
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type':  'application/json',
        'Authorization': localStorage.getItem("authtoken")
      })
    };
    return this.http.get(API_URL + "/profile").pipe(map(res => res));
  }
  
  private _errorHandler(error: HttpErrorResponse) {
    console.error("Error Occured: " + error);
    return Observable.throw(error || "Some Error on Server Occured");
  }
  updateLogo(formData: any) {
    const uri = API_URL + "/logo/";
    return this.http.post(uri, formData).pipe(
      catchError(this._errorHandler),
      map(res => {
        return res;
      })
    );
  }
  updateFavicon(formData: any) {
    const uri = API_URL + "/favicon/";
    return this.http.post(uri, formData).pipe(
      catchError(this._errorHandler),
      map(res => res)
    );
  }
  updateEmail(user, id) {
    return this.http
      .post(API_URL + "/update/" + id, user)
      .pipe(map(res => res));
  }
  updateSetting(user, id, symbol) {
    const obj = {
      username: user.username,
      email: user.email,
      footer: user.footer,
      apptitle: user.apptitle,
      appcontent: user.appcontent,
      phone: user.phone,
      distancePerCab: user.distancePerCab,
      tax: user.tax,
      maxDistance: user.maxDistance,
      currencyCode: user.currencyCode,
      siteName: user.siteName,
      currencySymbol: symbol,
      emergencyContact: user.emergencyContact,
      FCMUserKey: user.FCMUserKey,
      FCMDriverKey: user.FCMDriverKey,
      googleMapKey: user.googleMapKey,
      inspectionon: user.inspectionon,
      helppagesheader: user.helppagesheader,
      maxdisperride: user.maxdisperride
    };
    return this.http
      .post(API_URL + "/setting/" + id, obj)
      .pipe(map(res => res));
  }
  updateSMTP(user, id) {
    return this.http.post(API_URL + "/emailSetting/" + id, user);
  }
  getUserinfo() {
    this.loadToken();
    return this.http.get(API_URL + "/getProfile").pipe(map(res => res));
  }
  sendMail(form) {
    return this.http.post(API_URL + "/sendMail/", form).pipe(
      map(res => {
        return res;
      })
    );
  }
  ChangePassword(profile, id) {
    return this.http.post(API_URL + "/changePassword/" + id, profile).pipe(
      map(res => {
        return res;
      })
    );
  }
  updateApp(user, id) {
    return this.http.post(API_URL + "/app/" + id, user).pipe(map(res => res));
  }
  updatePayment(payment, id) {
    return this.http
      .post(API_URL + "/payment/" + id, payment)
      .pipe(map(res => res));
  }
}
