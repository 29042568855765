// The file contents for the current environment will overwrite these during build.
// The build system defaults to the dev environment which uses `environment.ts`, but if you do
// `ng build --env=prod` then `environment.prod.ts` will be used instead.
// The list of which env maps to which file can be found in `.angular-cli.json`.

export const environment = {
  production: true,
  domain: "",
  apiUrl: "https://streetrip.com:3000",
  socketUrl: "https://streetrip.com:8081",
  googleapiKey: "AIzaSyCfVn7LlncZehIUptidsWrQI95jJCYC7lM",
  googleloginClientid: "645101975805-b5jvp3vltm3pek20uahrniuq6odhh83p.apps.googleusercontent.com",
  facebookloginClientid: "",
  firebase: {
    apiKey: "AIzaSyCfVn7LlncZehIUptidsWrQI95jJCYC7lM",
    authDomain: "imposing-byway-427614-g6.firebaseapp.com",
    databaseURL: "https://imposing-byway-427614-g6-default-rtdb.firebaseio.com",
    projectId: "imposing-byway-427614-g6",
    storageBucket: "imposing-byway-427614-g6.appspot.com",
    messagingSenderId: "645101975805",
    appId: "1:645101975805:web:dfa68021f89b4143fca694",
    //measurementId: "ss"
  }
};


